import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Headless Delivery API`}</h1>
    <p>{`The Headless Delivery API is provided by the CoreMedia Headless Server, a component which allows access to CoreMedia content as JSON
through a GraphQL endpoint. That means, it does not deliver, for example, complete HTML pages but only the content
from the CMS as JSON data. This way, you as a customer, are free to use the frontend technology of your choice.`}</p>
    <p>{`In addition, the Headless Server offers a Java API which you can use for extensions of the Headless Server.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ccab7183f65b4bbbf4217e6a94add904/424e1/HeadlessServer_Concept_Dia.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABn0lEQVQoz2WSzW7TQBSF85bsgA08CxueAIkli6qLiEWXUNQWCt1EoYCbkjqNHXvs2OMZj+OfxPaHPElFBUe6uprRzDfnHs2o73sepE1BmsS2VJ7bdaa0raKs2AHd4WzXdbRty78aDUBjDFrnRHHM+0/fGJ9+JRIhQggcx8F1XRKlkc6EeH5D2/dUVcVut6OuawuWUto+GqhJkiCiCKMznr694MmbM+Ra4K9WTCYTbmczZG6Irk4RvyYMvpRSZFlmgdumwfd9mqbZA+uqoiwrtNZMfy9txVFEHMeIUFin2hgL6vhfw/jDlNbh4wwznVMWhnJjSNKUMAhY3C/wPM9mWpYb2rrmTme8vrrg1eUZH33X3t0d8twD+5728EphcjaFQWUSz/eZf/nJ4tZF5ooPx+dcn0+ZSsGLkzHPxse8c6bQdoRRxHa7/etw6EGScS8S5sEaL0oRImT23cGd35HlOSsvpEgV19malydjnh8fcXTzw973g2Cf4WNgmm+IlWGZaAKZUxQFsZSkmbLf5kGyKrkUPp9XSxa5snt109gs/wDQJVi3nrzWKwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ccab7183f65b4bbbf4217e6a94add904/e93cc/HeadlessServer_Concept_Dia.webp 300w", "/static/ccab7183f65b4bbbf4217e6a94add904/b0544/HeadlessServer_Concept_Dia.webp 600w", "/static/ccab7183f65b4bbbf4217e6a94add904/68fc1/HeadlessServer_Concept_Dia.webp 1200w", "/static/ccab7183f65b4bbbf4217e6a94add904/116c9/HeadlessServer_Concept_Dia.webp 1277w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ccab7183f65b4bbbf4217e6a94add904/eed55/HeadlessServer_Concept_Dia.png 300w", "/static/ccab7183f65b4bbbf4217e6a94add904/7491f/HeadlessServer_Concept_Dia.png 600w", "/static/ccab7183f65b4bbbf4217e6a94add904/8537d/HeadlessServer_Concept_Dia.png 1200w", "/static/ccab7183f65b4bbbf4217e6a94add904/424e1/HeadlessServer_Concept_Dia.png 1277w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ccab7183f65b4bbbf4217e6a94add904/8537d/HeadlessServer_Concept_Dia.png",
              "alt": "HeadlessServer Concept Dia",
              "title": "HeadlessServer Concept Dia",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Use Cases`}</h2>
    <p>{`The Delivery API supports most of the CoreMedia features such as:`}</p>
    <ul>
      <li parentName="ul">{`Multi-Site/Language delivery`}</li>
      <li parentName="ul">{`Responsive images`}</li>
      <li parentName="ul">{`Rich text transformation`}</li>
      <li parentName="ul">{`Image maps and shoppable videos`}</li>
      <li parentName="ul">{`Navigation and page grid support`}</li>
    </ul>
    <p>{`Studio contains a JSON preview client which displays the content as JSON.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to access content from CoreMedia Content Cloud through the Headless Server and learn how to extend the Headless Server
in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/headlessserver-en/content/Preface.html"
        }}>{`Headless Server Developer Manual`}</a></li>
      <li parentName="ul">{`See the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/artifacts/2406.0.3/javadoc/headless-server/index.html"
        }}>{`Headless Server Java API`}</a>{` for extensions of the Headless Server.`}</li>
      <li parentName="ul">{`Learn how to enable the JSON preview in Studio in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/studio-developer-en/content/MultiplePreviewsConfiguration.html"
        }}>{`Studio Developer Manual`}</a></li>
      <li parentName="ul">{`Study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/coremedia-headless-client-react"
        }}>{`Sparc example site`}</a>{` a React example application based on React, TypeScript and the Headless Server of CoreMedia Content Cloud`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      